

.header{
    color: aqua;
    display: flex;
    justify-content: space-evenly;
    position:fixed;
    top: 10vh;
    width: 50vh;
  } 

a{ cursor: default;
    text-decoration: none;
    color: rgb(22, 219, 81)
}
a:hover{
    
    color: hotpink;
}
h1 {
    color: rgb(22, 219, 81);
}