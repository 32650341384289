.container {
    border: solid;
    border-color:rgb(35, 202, 43);
display: flex;
flex-direction: row;
    color: aliceblue;
}


.about {
     padding: 10%;
    color:rgb(35, 202, 43);
    font-size: 20px;


}
/* h1{
    font-size: 20px;
    color: rgb(0, 255, 81);
} */
