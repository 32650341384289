.App {
  display: flex;
flex-direction: column;
  justify-content: center; 
  column-gap: 20px;

}

/* .header{
  padding-bottom: 150%;
   height: 2%;
} */